<template>
	<v-container class="fill-height" fluid>
		<v-row align="center" justify="center">
			<v-col cols="12" md="9" lg="6" xl="4">
				<v-card elevation="12">
					<v-toolbar color="orange">
						<v-toolbar-title>江苏赛洋统一认证平台</v-toolbar-title>
						<v-spacer />
						<v-tooltip bottom>
							<template #activator="{ on }">
								<v-btn
									large
									icon
									target="_blank"
									href="mailto:newflydd@gmail.com"
									v-on="on"
								>
									<v-icon>mdi-email</v-icon>
								</v-btn>
							</template>
							<span>newflydd@gmail.com</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template #activator="{ on }">
								<v-btn large icon v-on="on">
									<v-icon>mdi-phone</v-icon>
								</v-btn>
							</template>
							<span>18005268068</span>
						</v-tooltip>
						<v-tooltip v-if="user !==null" bottom>
							<template #activator="{ on }">
								<v-btn large icon v-on="on" @click="toWorkSpace()">
									<v-icon>mdi-cog</v-icon>
								</v-btn>
							</template>
							<span>前往控制台</span>
						</v-tooltip>
						<v-btn v-if="user !==null" large icon @click="logout()">
							<v-icon>mdi-exit-to-app</v-icon>
						</v-btn>							
					</v-toolbar>
					<v-card-text class="card_box">
						<v-form v-if="user === null" ref="form" v-model="valid" class="box_item">
							<v-text-field
								v-model="staffCode"
								label="工号"
								name="login"
								prepend-icon="mdi-account"
								type="text"
								:rules="rules.staffCode"
							/>
							<v-text-field
								v-model="password"
								label="密码"
								name="password"
								prepend-icon="mdi-lock"
								type="password"
								:rules="rules.password"
							/>
							<div ref="vaptcha" style="width: 100%; height: 36px; margin-bottom: 10px">
								<div class="vaptcha-init-main">
									<div class="vaptcha-init-loading">
										<a href="/" target="_blank">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												xmlns:xlink="http://www.w3.org/1999/xlink"
												width="48px"
												height="60px"
												viewBox="0 0 24 30"
												style="enable-background: new 0 0 50 50; width: 14px; height: 14px; vertical-align: middle"
												xml:space="preserve"
											>
												<rect x="0" y="9.22656" width="4" height="12.5469" fill="#CCCCCC">
													<animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s" repeatCount="indefinite" />
													<animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s" repeatCount="indefinite" />
												</rect>
												<rect x="10" y="5.22656" width="4" height="20.5469" fill="#CCCCCC">
													<animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
													<animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
												</rect>
												<rect x="20" y="8.77344" width="4" height="13.4531" fill="#CCCCCC">
													<animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
													<animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
												</rect>
											</svg>
										</a>
										<span class="vaptcha-text">Vaptcha启动中...</span>
									</div>
								</div>
							</div>
						</v-form>

						<div v-else class="box_item">
							<v-avatar size="100" class="mt-8">
								<img :src="user.Avatar">
							</v-avatar>
							<v-list-item color="rgba(0, 0, 0, .4)">
								<v-list-item-content>
									<v-list-item-title class="text-h6">
										{{ user.Name }}
									</v-list-item-title>
									<v-list-item-subtitle>
										{{ user.DepartmentStr }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</div>

						<div v-if="app != null" class="box_item">
							<v-avatar size="100" tile class="mt-8">
								<img :src="app.Icon">
							</v-avatar>
							<v-list-item color="rgba(0, 0, 0, .4)">
								<v-list-item-content>
									<v-list-item-title class="text-h6">
										{{ app.Title }}
									</v-list-item-title>
									<v-list-item-subtitle>
										{{ app.Desc }}
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</div>
						<div v-if="app != null && user != null" class="transArrow" :class="{stopAnimation: user.HasApp(app.ID)}">
							<div class="line" />
							<v-icon class="arrow">
								mdi-chevron-right
							</v-icon>
						</div>
					</v-card-text>
					<v-card-actions v-if="waitSec != -1" class="justify-center">
						<h4>您已经授权该系统获取您的身份信息，请等待 <span class="primary--text">{{ waitSec }}</span> 秒钟跳转，或<a :href="jumpUrl">点击链接</a>直接跳转。</h4>
					</v-card-actions>
					<v-card-actions v-else>
						<v-spacer />
						<v-btn color="orange" @click="loginOrAuth">
							授权登录
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import User from '@/model/user';
import {log} from '@/utils';

let vaptchaObj; // vue3变量定义要放在外面
export default {
	name: 'EntryWindow',
	data() {
		return {
			staffCode: '',
			password: '',
			sliderValue: 0,	
			rules: {
				staffCode: [
					v => !!v || '工号不能为空',					
					v => (v && /^\d{5}$/.test(v)) || '工号为5位数字',
				],
				password: [
					v => !!v || '密码不能为空',
					v => (v && v.length >= 6) || '密码长度不能小于6位',
				],
				slider: [
					v => v === 100 || '请将滑块滑动至最右端',
				]
			},
			valid: false,
			waitSec: -1,
			jumpUrl: '',
			timer: 0,
		}
	},

	computed: {
		...mapGetters(['user', 'app']),		
	},

	created(){
		if(this.user && !this.app)
			this.$router.push('/WorkSpace');
		this.judgeJump();
	},

	mounted(){
		if (this.user == null){
			this.loadVaptcha();
		}
	},

	methods: {
		loginOrAuth() {
			if (this.user == null){
				this.login();
			}else{				
				if(this.app == null){
					this.$router.push('/WorkSpace');
				}else{
					this.auth();
				}
			}			
		},

		login() {
			this.$refs.form.validate();			
			if(this.valid) {
				let vaptcha = this.getServerToken();
				if (vaptcha.token === '') {
					this.$store.commit('Notice', {msg: '请先进行人机校验.', color: 'warning'});
					return
				}
				User.Login(this.staffCode, this.password, this.app ? this.app.ID : 0, vaptcha).then(user => {
					user = Object.assign(new User(), user);
					user.Local();
					if(!this.app){
						this.$router.push('/WorkSpace');
						return;
					}
					this.judgeJump();
				}).catch(() => {
					this.password = '';
					this.sliderValue = 0;
				});
			}
		},

		auth(){
			this.user.MakeApp(this.app.ID, 1).then((user) =>{
				user = Object.assign(new User(), user);
				user.Local();
				if(!this.app){
					this.$router.push('/WorkSpace');
					return;
				}
				this.judgeJump();
			}).catch(err => {
				log.err(err);
			});
		},

		logout() {			
			User.Clear();			
			clearInterval(this.timer);
			this.waitSec = -1;
			this.loadVaptcha();
		},

		toWorkSpace(){
			clearInterval(this.timer);
			this.waitSec = -1;
			this.$router.push('/WorkSpace');
		},

		judgeJump(){
			if(this.user && this.app && this.user.AppToken && this.user.HasApp(this.app.ID)){
				this.waitSec = 5;
				this.jumpUrl = this.app.Callback + this.user.AppToken;
				let that =  this;
				this.timer = setInterval(() => {					
					that.waitSec --;
					if(that.waitSec == 0){
						window.location.href = that.jumpUrl;
					}
				}, 1000);
			}
		},

		loadVaptcha(){			
			this.loadV2Script().then(() => {
				setTimeout(() => {
					// 1秒后延时加载vaptcha到dom
					this.loadVaptchaDom();
				}, 1000)
			}).catch(err=>{
				log.err(err);
			});
		},

		loadVaptchaDom() {
			let config = {
				vid: '6269f27ba36b69b0b7226e99',
				mode: 'click',
				scene: 0,
				container: this.$refs.vaptcha,
				style: 'light',
				color: '#00BFFF',
				lang: 'auto',
				area: 'auto'
			};
			window.vaptcha(config).then((obj) => {
				vaptchaObj = obj;

				obj.listen('pass', function() {					
				})
				obj.listen('close', function () {
					obj.reset();
				})

				obj.render();
			}).catch(err=>{
				log.err(err);
			})
		},

		loadV2Script() {
			if (typeof window.vaptcha === 'function') {
				//如果已经加载就直接放回
				return Promise.resolve()
			} else {
				return new Promise((resolve) => {
					var script = document.createElement('script')
					script.src = 'https://v.vaptcha.com/v3.js'
					script.async = true
					script.onload = script.onreadystatechange = function () {
						if (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete') {
							resolve()
							script.onload = script.onreadystatechange = null
						}
					}
					document.getElementsByTagName('head')[0].appendChild(script)
				})
			}
		},

		getServerToken() {
			return vaptchaObj.getServerToken();
		},
		reset() {
			vaptchaObj.reset();
		},
	}
};
</script>

<style scoped>
.card_box{
	display: flex;
}
.box_item{
	flex-grow: 1;
	flex-basis: 200px;
	text-align: center;
}

.stopAnimation{
	animation: none !important;
	-webkit-animation: none !important;
}

.stopAnimation .line{
	border-bottom: solid 3px green;
}
.stopAnimation .arrow {
	color: green !important;
}

.transArrow{
	position: absolute;
	top: 40%;
	height: 3rem;
	left: calc(25% + 70px);
	width: calc(50% - 140px);
	-webkit-animation: mymove 5s infinite; /* Chrome, Safari, Opera */
    animation: mymove 2s infinite;
	overflow: hidden;
}
.line{
	height: 1.5rem;
	line-height: 1.5rem;
	border-bottom: dashed 3px #777;
	margin-left: 1.5rem;
}
.arrow{
	position: absolute;
	top: -19px;
	left: calc(100% - 23px);
	font-size: 36px !important;
}
/* Chrome, Safari, Opera */
@-webkit-keyframes mymove {
    50% {width: 3rem;}
}

/* Standard syntax */
@keyframes mymove {
    50% {width: 3rem;}
}

.vaptcha-init-main {
	display: table;
	width: 100%;
	height: 100%;
	background-color: #eeeeee;
}
.vaptcha-init-loading {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}
.vaptcha-init-loading > a {
	display: inline-block;
	width: 18px;
	height: 18px;
}
.vaptcha-init-loading > a img {
	vertical-align: middle;
}
.vaptcha-init-loading .vaptcha-text {
	font-family: sans-serif;
	font-size: 12px;
	color: #cccccc;
	vertical-align: middle;
}
</style>
