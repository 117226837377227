import Vue from 'vue'
import Config from '@/utils/config'
import ajax from '@/utils/ajax'
import log from '@/utils/log'
import store from '@/store'

class User {
	ID = 0;
	Name = '';
	Token = '';
	Avatar = '';
	CarNum = '';
	Checked =  1;
	Department = '';
	DepartmentStr = '';
	Email = '';
	IDCardCode = '';
	Phone = '';
	Power = 0;
	StaffCode = '';
	Apps = [];
	AppToken = '';

	constructor(...args){
		if (args.length === 0) {
			this.ID = 0;
			this.Name = '';
			this.Token = '';
			this.Avatar = '';
			this.CarNum = '';
			this.Checked =  1;
			this.Department = '';
			this.DepartmentStr = '';
			this.Email = '';
			this.IDCardCode = '';
			this.Phone = '';
			this.Power = 0;
			this.StaffCode = '';
			this.Apps = [];
			this.AppToken = '';
		}else if (args.length === 1) {
			Object.assign(this, args[0]);
		}
	}

	static Init(){
		let user = new User();
		user.Token = Vue.ls.get(Config.ACCESS_TOKEN); 
		return user;
	}

	static Login(staffCode, password, appID, vaptcha){
		log.info('post:/login')
		return ajax.post('/login', {
			StaffCode: staffCode,
			Password: password,
			AppID: appID,
			VaptchaToken: vaptcha.token,
			VaptchaServer: vaptcha.server,
		});
	}

	CheckLogin(appID){
		log.info('post: /staff_check', appID)
		return ajax.post('/staff_check', {
			AppID: appID,
		});
	}

	Local(){
		store.commit('SetUser', this);		
	}

	static Clear(){
		store.commit('SetUser', null);		
	}

	HasApp(id){
		for (let app of this.Apps){
			if (app.ID === id) {
				return true;
			}
		}
		return false;
	}

	MakeApp(appID, connect){
		log.info('post: /staff_app', appID);
		return ajax.post('/staff_app', {
			StaffID: this.ID,
			AppID: appID,
			Connect: connect,
		});
	}

	ChangePwd(oldPwd, newPwd){
		log.info('post: /staff_change_pwd');
		return ajax.post('/staff_change_pwd', {
			OldPwd: oldPwd,
			NewPwd: newPwd,
		});
	}
}

export default User;