import Notice from '@/model/notice';

export default {
	state: {
		notice: new Notice(false, '', 'error', 6000),
	},
	getters: {
		notice: state => state.notice,
	},
	mutations: {
		Notice(state, notice) {
			if (typeof notice === 'string') {
				state.notice = new Notice(true, notice, 'error', 6000);
			} else {
				notice.show = true;				
				state.notice = notice;
			}
		}
	},
};