// utils/ajax.js
import Vue from 'vue'
import axios from 'axios'
import Config from '@/utils/config'
import log from '@/utils/log'
import store from '@/store'
import Notice from '@/model/notice'

const ajax = axios.create({
	baseURL: '/api',
	timeout: 5000,
});

/* process the http status error */
const err = (error) => {
	if (error.response) {
		// TODO: do something
	}
	log.warn(error);
	store.commit('Notice', new Notice(true, error, 'error', 6000));
	return Promise.reject(error);
};

ajax.interceptors.request.use((config) => {
	const token = Vue.ls.get(Config.ACCESS_TOKEN);
	if (token) {
		config.headers.Authorization = token;
	}
	if (config.method === 'post') {
		config.data = JSON.stringify(config.data);
		config.headers['Content-Type'] = 'application/json';
	}
	return config;
});

ajax.interceptors.response.use((response) => {
	if (response.data['ErrCode']) {
		/* process the error code */
		if (response.data['ErrCode'] === 401) {			
			window.location.href = Config.LOGIN_PAGE;
		}
		log.warn(response.data['ErrMsg']);
		store.commit('Notice', new Notice(true, response.data['ErrMsg'], 'error', 6000));
		return Promise.reject(response.data);
	}

	return Promise.resolve(response.data);
}, err);

export default ajax;