import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Storage from 'vue-ls'
import router from './router'
import store from './store'

Vue.config.productionTip = false

let vuelsOptions = {
	namespace: 'login.saiyang_', // key prefix
	name: 'ls', // name variable Vue.[ls] or this.[$ls],
	storage: 'local' // storage name session, local, memory
};
Vue.use(Storage, vuelsOptions);

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
