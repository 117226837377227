<template>
	<v-app>
		<v-main>
			<router-view />
		</v-main>
		<v-snackbar
			v-model="notice.show" :color="notice.color" :timeout="notice.timeout"
			:right="true" :top="true"
		>
			{{ notice.msg }}
		</v-snackbar>
	</v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import User from '@/model/user';
import log from '@/utils/log';
import App from '@/model/app';

export default {
	name: 'App',

	data: () => ({
	}),

	computed: {
		...mapGetters(['user', 'notice', 'app']),
	},

	created(){
		// decode parameters
		let app64 = this.GetQueryString('app');
		if (app64 && app64 !== ''){
			let appParams = JSON.parse(window.atob(app64));
			App.Check(appParams).then(data => {
				let app = new App(data)
				if(app.From != document.referrer){
					this.$store.commit('Notice', '请求授权的系统源地址不正确');
					App.Clear();
				}else{
					app.Local();
				}				
			}).catch(error => {
				log.warn(error);
				App.Clear();
			}).finally(() => {
				this.checkLogin();
			});
		}else {
			App.Clear();
			this.checkLogin();
		}		
	},

	methods: {
		GetQueryString(name) {
   			let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
			let r = window.location.search.substring(1).match(reg);
			if (r!=null)
				return decodeURIComponent(r[2]);
			return null;
		},

		checkLogin(){
			console.log('caheckLogin');
			User.Init().Local();
			if(this.user.Token && this.user.Token !== ''){
				this.user.CheckLogin(this.app ? this.app.ID : 0).then(user => {
					user.Token =  this.user.Token;
					user = new User(user);
					user.Local();
					if(this.app !== null){
						// has staff, has app
						this.$router.push('/EntryWindow')
					}else {
						// has staff, no app
						this.$router.push('/WorkSpace')
					}				
				}).catch((err) => {
				// no staff
					log.warn(err);
					User.Clear();
					this.$router.push('/EntryWindow')
				});
			}else {
				// no staff
				User.Clear();
				this.$router.push('/EntryWindow')
			}	
		}
	},
};
</script>

<style>
html, .pretty-scroll{
	overflow-y: auto !important;
}
html::-webkit-scrollbar{
    width:10px;
}
html::-webkit-scrollbar-thumb{
    background-color:#BDBDBD;
    border-radius:5px;
}
</style>
