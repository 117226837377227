import Vue from 'vue'
import VueRouter from 'vue-router'
import EntryWindow from '@/pages/EntryWindow'

// 防止重复路由
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/EntryWindow',
		component: EntryWindow,
	},
	{
		path: '/WorkSpace',
		component: () => import('@/pages/WorkSpace/WorkSpace'),
		children: [
			{path: '', redirect: '/WorkSpace/MyApp'},
			{path: 'MyApp', component: () => import('@/pages/WorkSpace/MyApp')},
			{path: 'MyAccount', component: () => import('@/pages/WorkSpace/MyAccount')},
		],
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
