import Config from '@/utils/config'

// info:0, debug:1, warn:2, err:3
const lvInfo = 0;
const lvDebug = 1;
const lvWarn = 2;
const lvErr = 3;
const log = Object();

const level = Config.DEBUG_LEVEL;

if (level <= lvInfo){
	log.info = console.info;
}else {
	log.info = () => {};	
}

if (level <= lvDebug){
	log.debug = console.debug;
}else {
	log.debug = () => {};
}

if (level <= lvWarn){
	log.warn = console.warn;
}else {
	log.warn = () => {};	
}

if (level <= lvErr){
	log.err = console.error;
}else {
	log.err = () => {};	
}

export default log;