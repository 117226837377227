import log from '@/utils/log'
import store from '@/store'
import ajax from '@/utils/ajax'

export default class {
	ID = 0;
	Secret = '';
	Title = '';
	Icon = '';
	Desc = '';
	Callback = '';
	From = '';

	constructor(...args){
		if (args.length === 0) {
			this.ID = 0;
			this.Secret = '';
			this.Title = '';
			this.Desc = '';
			this.Icon = '';
			this.Callback = '';
			this.From = '';
		}else if (args.length === 1) {
			Object.assign(this, args[0]);
		}
	}

	static Check(param){
		log.info('post:/app_check', param.ID, param.Secret);
		return ajax.post('/app_check', {ID: param.ID, Secret: param.Secret});
	}

	Local(){
		store.commit('SetApp', this);
	}

	static Clear(){
		store.commit('SetApp', null);
	}
}